import 'animate.css/animate.css';

import loadable from '@loadable/component';
import { graphql, Link } from 'gatsby';
import React, { useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap'; // , Container
import Rellax from 'rellax';
import ScrollOut from 'scroll-out';

import AddressSearch from '../components/addressPartners';
import AddressSearchLife from '../components/addressPartnersLife';
import BubbleTestimony from '../components/BubbleTestimony';
import FaqSection from '../components/faq';
import FooterCommon from '../components/footer-common';
import InsuranceLearnMore from '../components/InsuranceLearnMore';
import MenuTemplate from '../components/menu-template';
import PartnerLogo from '../components/PartnerLogos';
import SEO from '../components/seo';
import SmartAssurance from '../components/SmartAssurance';
import bubbleemailer from '../images/bubble-emailer-final-3.png';
import bubbleemailersmall from '../images/bubble-emailer-small.png';
import facebook from '../images/facebook.svg';
import instagram from '../images/instagram.png';
import linkedin from '../images/linked-in.svg';
import oval1 from '../images/oval1.svg';
import oval2 from '../images/oval2.svg';
import oval3 from '../images/oval3.svg';
import oval4 from '../images/oval4.svg';
import PRMGLogo from '../images/PRMG-Logo.png';
const Bubbletonerbanner = 'https://cdn.getmybubble.com/images/corporate-sites/Bubble_Emailer.webp';
// import smallCircle from "../images/smallCircle.svg";
import twitter from '../images/twitter.svg';
//import * as headerStyles from '../styles/common.module.css';
import * as headerStyles from '../styles/common.module.css';
import VerticalSlider from './VerticalSlider';
const HomePageBlogs = loadable( () => import( '../components/homePageBlogs' ) );


export const pageQuery = graphql`
query {
  allStrapiArticle(filter: {page_name: {eq: "home_page"}, status: {eq: "Production"}, data_category: {eq: "did_you_know"}}) {
    edges {
      node {
        body
        id
        title
      }
    }
  }
  licenseNumbers: allStrapiPageData(filter: {page_name: {eq: "license_numbers"}}) {
    edges {
      node {
        data_category
        column
        content
        style_category
        attribute_1_value
        attribute_2_value
        attribute_1_key
        attribute_2_key
      }
    }
  }
  allStrapiUser {
    edges {
      node {
        email
        username
        role {
          name
        }
      }
    }
  }
  allStrapiPost(limit: 3, sort: {fields: published_at, order: DESC} , filter:{category: {eq : "BLOG"}}) {
    edges {
      node {
        id
        body
        category
        className
        created_at
        image{
          publicURL
        }
        published_at
        title
        strapiId
        id
      }
    }
  }

  allStrapiPageData(filter: {page_name: {eq: "partner_logo"}}) {
    edges {
      node {
        id
        page_name
        style_category
        content
        attribute_1_key
        attribute_1_value
        attribute_2_key
        attribute_2_value
        column
        data_category
        version
      }
  }
}
}
`;

const blogData = [
  { type: 'Life Insurance', color: '#0d4d90', background: 'rgba(13, 77, 144, 0.1)' },
  { type: 'Others', color: '#5e5e5e', background: 'rgba(207, 207, 207, 0.3)' },
  { type: 'Home Insurance', color: '#9375ff', background: 'rgba(147, 117, 255, 0.1)' }
];

const IndexPage = ( { data } ) => {
  const faqArray = [
    {
      question: 'What are the advantages of buying home and life insurance together?',
      answer: 'You need home insurance to protect your home and your personal belongings. Life insurance does the same for your family when you are not around anymore. Buying them together means being prepared for any contingency.'
    },
    {
      question: ' What if I just need one of either life or home insurance?',
      answer: 'Sure, you can buy either one. But we strongly advise you to bundle them together for well-rounded protection for your loved ones.'
    },
    {
      question: 'Can I buy only term policies at Bubble?',
      answer: 'At present you can only buy term policies at Bubble. We help you find the right term policy that you’re comfortable with and one that fits your budget. In future, we shall be offering many more products.'
    },
    {
      question: 'How does Life Insurance fit in with my household\'s other financial responsibilities?',
      answer: 'Our smart guidance platform takes into account your other financial responsibilities, your family circumstances and other factors to help you find the most appropriate coverage and term for your policy. The payout can help your family with mortgage payments, or children\'s college tuition, and they can be financially secure even when you are not there anymore. Plan your expenses right and you will be glad to have bought a policy.'
    },
    {
      question: 'Does everybody really need a medical test to get life insurance?',
      answer: 'No. In fact, Bubble partner carriers provide accelerated, fluidless, no-exam underwriting for close to half the applicants in the 25-45 age group.'
    },
    {
      question: 'What does Home Insurance cover?',
      answer: 'Home insurance helps pay for lost or stolen personal belongings and for repairs or rebuilding your home in case of any damage caused by perils like theft, fire, flood, and so on. It may also cover hotel stay if your home is too damaged to live in.'
    },
    {
      question: 'Is it legally mandatory to buy Home Insurance?',
      answer: 'Legally, it is possible to own a home without home insurance, but your mortgage provider may require that you buy it to protect their financial interests.'
    },
    {
      question: ' Is it too complicated and a lengthy process to buy home or life insurance?',
      answer: 'It used to be a complicated process years ago. Buying has now become simple, fast and easy with the help of technology and user journeys designed by companies, such as Bubble. '
    }
  ];


  useEffect( () => {
    new Rellax( '.rellax', { // <---- Via class name
      speed: 5
    } );
  }, [] );

  useEffect( () => {
    ScrollOut( {
      threshold: .5,
      once: false,
      cssProps: {
        viewportY: true,
        visibleY: true
      }
    } );
  }, [] );


  return (
    <div>

      <SEO
        title="Bundled Home and Life"
        description="Digitally bundled life &amp; home insurance for homeowners to protect their homes and their loved ones! Visit now to see how little it costs."
        keywords="Bundled Insurance Plans Life and Home Insurance Best Bundle Insurance"
      />

      <script>
        var rellax = new Rellax('.rellax');
        console.log('Hello from rellax')
        ScrollOut();
      </script>

      {/* <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyB_DcLM8ITEURJDmtF5T8CQrQBCOqkCc3o&fields=formatted_address,places&libraries=places">

      </script> */}

      <MenuTemplate/>

      <div  className="wrapper">
        {/* banner section */}

        <div className={headerStyles.mainBannerWrapper + ' toner-header1'}>
          <div className="container-fluid">

            <div className="row align-items-center justify-content-center d-flex">

              <div className="banner-left-bar pt-0 pb-0 text-center ">
                <img src={Bubbletonerbanner} alt="Toner" className="img-fluid"/>
              </div>

              <div className="banner-right-bar">
                <div className="pt-0 pb-0 banner-text">
                  <h2 className={headerStyles.hpMainHeader} data-scroll> Hi there!  <div> Paramount Residential Mortgage Group Inc </div> has partnered with Bubble to offer great online home and life insurance options for its customers</h2>

                  <h4 className={headerStyles.hpSecondHeader} data-scroll>Smart Insurance. Total Assurance.</h4>
                </div>

                <div className="navsection">
                  <Tabs defaultActiveKey="homeandlife" id="controlled-tab-example">
                    <Tab eventKey="homeandlife" title="Home and Life">
                      <div className="col-12 pt-0 pb-0 text-center">
                        <AddressSearch parentPage="PRMG" insuranceType='homeLife' licenseState={data.licenseNumbers.edges} />
                      </div>
                    </Tab>

                    <Tab eventKey="home" title="Home">
                      <div className="col-12 pt-0 pb-0 text-center">
                        <AddressSearch parentPage="PRMG" insuranceType='home' licenseState={data.licenseNumbers.edges} />
                      </div>
                    </Tab>

                    <Tab eventKey="life" title="Life">
                      <div className="col-12 pt-0 pb-0 text-center">
                        <AddressSearchLife parentPage="PRMG" insuranceType='life' />
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>

            </div>

          </div>
        </div>

        {/* second part div struct */}

        <div id="scrollDiv" className="vertical-slidebox">
          <div className="container common-container-space">
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="slide-head" data-scroll> See why Home and <br />Life Insurance belong together</h2>
              </div>
            </div>
          </div>

          <VerticalSlider allStrapiArticle={data.allStrapiArticle.edges} />
        </div>

        {/* third part div struct */}

        <InsuranceLearnMore/>

        <div className="toner-getin-touch-section">
          <div className="container">
            <div className="toner-getin-touch-box">
              <div className="toner-getin-touch-innerbox1">
                <img src={PRMGLogo} alt="logo"/>

                <div>866-776-4937</div>

                <div>afs@prmg.net</div>

                <ul>
                  <li> <a href="https://www.facebook.com/PRMGFanPage" target="_blank" rel="noreferrer"><img src={facebook} alt="fb"/></a></li>

                  <li> <a href="https://www.instagram.com/prmginc/" target="_blank" rel="noreferrer"><img src={instagram} alt="insta"/></a></li>

                  <li> <a href="https://twitter.com/PRMGCorp" target="_blank" rel="noreferrer"><img src={twitter} alt="twit"/></a></li>

                  <li> <a href="https://www.linkedin.com/company/381079?trk=NUS_CMPY_FOL-co" target="_blank" rel="noreferrer"><img src={linkedin} alt="linkedin"/></a></li>

                </ul>

                <p>Since 2001, Paramount Residential Mortgage Group Inc. (PRMG) continues to be a Top 25 Leading Lender in the mortgage industry. As a privately held mortgage banker and residential home lender, PRMG has successfully helped many borrowers purchase and refinance their homes throughout the United States.</p>

                <p>Our goal is to provide customers with a 'Path to Pivot' to more consumer facing activities and modern lending technology. This includes innovative products and industry-leading customer service levels.</p>

                <a href="mailto:afs@prmg.net" className="getstart-btn">Get In Touch</a>
              </div>

              <div className="toner-getin-touch-innerbox2">
                <a><img src={bubbleemailer} alt="email"/></a>
              </div>
            </div>
          </div>

          <img src={oval1} alt="Toner" className="toner-bgbtleftsmall rellax" data-rellax-speed="2" />

          <img src={oval2} alt="Toner" className="toner-bgtoprtsmall rellax" data-rellax-speed="2" />

          <img src={oval3} alt="Toner" className="toner-bgbtrtsmall rellax" data-rellax-speed="2" />

          <img src={oval4} alt="Toner" className="toner-mobfirst rellax" data-rellax-speed="2" />
        </div>

        <div className="container">
          <div className="toner-getin-touch-section2">
            <div>
              <a><img src={bubbleemailersmall} alt="email"/></a>
            </div>

            <div>
              <h4>Got any questions?</h4>

              <p>For any insurance-related queries, get in touch and we'll help you</p>

              <Link to="/" className="getstart-btn">Get In Touch</Link>
            </div>
          </div>
        </div>


        <BubbleTestimony/>

        <SmartAssurance/>

        {/* fourth part div struct */}

        <div className="workslide-box">
          <div className="container common-container-space">
            <div className="row">
              <div className="workslide-box-text">
                <h3 className="box-head pb-4 text-center">Bubble only works with highly rated partners.</h3>
              </div>
            </div>

            {data.allStrapiPageData.edges.map( ( _, i ) => {
              return ( <div className="brandlogo-list" key={i}>
                {/* <div className="row align-items-center">

                  <div className="col-6 col-md-4 mx-auto text-center">
                    <a href="">
                      <img src={paclifenext} className="pacLifeImage"  alt="PacLife Logo" />
                    </a>
                  </div>

                  <div className="col-6 col-md-4 text-center">
                    <a href="">
                      <img src={sbli1} className="sbliImage"  alt="SBLI Logo" />
                    </a>
                  </div>

                  <div className="col-12 col-md-4 mx-auto text-center mobLogo">
                    <a href="">
                      <img src={stillwater1}  className="stillWater" alt="Stillwater Logo" />
                    </a>
                  </div>


                </div> */}

                <PartnerLogo></PartnerLogo>
              </div> );
            } )}
          </div>
        </div>


        {/* FAQ section */}

        <FaqSection  faqs={faqArray}/>

        {/* Blog section */}

        <div className="blog-box">
          <div className="container common-container-space">
            {/* style={{"padding":"0 6.9135%"}} */}

            <div className="row">
              <div className="col-12 text-center">
                <h2>Check out Bubble’s insights</h2>
              </div>

              <div className="col-12" >
                <div className="card-deck blog-section1">
                  { /* Converted To Component To Enable Lazy Loading */ }

                  <HomePageBlogs />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      {/* <FooterTemplate/> */}

      <FooterCommon>
        <p>Paramount Residential Mortgage Group Inc offers insurance policies through our partner at Bubble Insurance Solutions </p>
      </FooterCommon>

    </div>
  );
};

export default IndexPage;
